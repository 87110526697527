<script context="module">
  import { thumbnail } from "../util/filepreview";
  import { parseISO } from "date-fns";
  import { utcToZonedTime, format } from "date-fns-tz";
  import { identity } from "lodash-es";
  import Fees from "./Fees.svelte";
  import StripePayment from "./StripePayment.svelte";
</script>

<script>
  import Record from "./RecordItem.svelte";
  import { fetchPayFee } from "../util/api";
  import { tick } from "svelte";

  export let violation = null;

  $: exceptions = Object.values(violation?.exceptions?.items || []);
  $: files = Object.values(violation?.files || []);
  $: notes = Object.values(violation?.notes || []);

  $: console.log("violation=", violation);
  $: payment = violation?.payment;
  $: fees = Object.values(payment?.fees.items || {});
  $: hasFees = fees && fees.length;
  $: console.log("payment=", payment);
  // find unpaid fee
  $: fee = Object.values(payment?.fees?.items || {}).find(
    (item) => item.payments.balance.value > 0
  );
  $: console.log("fee=", fee);
  $: paymentRequired = !!fee;

  let paymentMethod;
  let paymentToken;
  let requestPayment;

  $: console.log("method=", paymentMethod);

  let submitting = false;
  $: submittable = !paymentRequired || !!paymentMethod;

  async function submitPayment(e) {
    e.preventDefault();
    if (submitting) return;
    submitting = true;
    if (paymentRequired && paymentMethod && requestPayment) {
      var paymentResult = await requestPayment();
      //console.log("paymentresult=", paymentResult);
      paymentToken =
        paymentResult && paymentResult.token && paymentResult.token.id;

      await tick();
    }

    if (!paymentToken) {
      //submitting = false;
      return (submitting = false); // done!
    }

    // continue on with saving...
    const json = await fetchPayFee(
      fee.id,
      paymentToken,
      fee.payments.balance.value
    );

    if (json.status && json.message) return alert(json.message);

    // update fee
    Object.assign(payment.fees.items, json.fees.items);
    payment = violation.payment = payment;
    return (submitting = false);
  }
</script>

{#if violation}
  <header>
    <h1><data value={violation.id}>{violation.title}</data></h1>
    {#if violation.revoked}
      <data class="revoked" value={violation.id}>Removed</data>
    {/if}
    {#if paymentRequired}
      <data class="unpaid fee" value={fee.id}>Unpaid</data>
    {/if}
    {#each exceptions as item}
      <time class="violation exception" datetime={item.interval}
        ><data value={item.count}>{item.title}</data></time
      >
    {/each}
  </header>
  {#if violation.revoked}
    <dl class="subjects">
      <dt>Removed</dt>
      <dd>
        <time datetime={violation.revoked.datetime}
          ><abbr title={parseISO(violation.revoked.datetime).toString()}
            >{format(
              utcToZonedTime(
                parseISO(violation.revoked.datetime),
                violation.timezone
              ),
              "h:mm a EEE MMM d yyyy",
              {
                timeZone: violation.timezone,
              }
            )}</abbr
          ></time
        >
      </dd>
    </dl>
  {/if}
  <dl class="subjects">
    {#each [violation.vehicle, violation.media, violation.tenant].filter(identity) as record}
      <dt>{record.title || record.format || record.type}</dt>
      <dd>
        <Record item={record} />
      </dd>
    {/each}
    {#each [violation.space].filter(identity) as record}
      <dt>Parked In</dt>
      <dd>
        <data
          class={["id", record._type || record.type, record.format]
            .filter(identity)
            .join(" ")}>{record.display}</data
        >
      </dd>
    {/each}
    <!-- </dl>
  <dl class="subjects"> -->
    {#each [violation.notice].filter(identity) as record}
      <dt>{record.title || record.format || record.type}</dt>
      <dd>
        <data
          class={["id", record.type, record.format].filter(identity).join(" ")}
          >{record.display}</data
        >
      </dd>
    {/each}
  </dl>
  <ul class="notes">
    {#each [violation.issued.reason].filter(identity) as text}
      <li class="notes"><blockquote><p>{text}</p></blockquote></li>
    {/each}
  </ul>
  <ul class="files">
    {#each files as item}
      <li class="file">
        <figure>
          <a href={item.url} target="_blank"
            ><img src={thumbnail(item.url, 80, 80, true)} /></a
          >
        </figure>
      </li>
    {/each}
  </ul>
  <!-- <dl class="subjects">
    {#each exceptions as item}
    <dt>Flagged</dt><dd><time class="exception" datetime="{item.interval}"><data value="{item.count}">{item.title}</data></time></dd>
    {/each}
</dl> -->
  <!-- <ul class="exceptions">
    {#each exceptions as item}
    <li class="exception"><h1>{item.title}</h1></li>
    {/each}
</ul> -->

  <dl class="subjects">
    <dt>{"Recorded" || violation.issued.title}</dt>
    <dd>
      <time datetime={violation.issued.datetime}
        ><abbr title={parseISO(violation.issued.datetime).toString()}
          >{format(
            utcToZonedTime(
              parseISO(violation.issued.datetime),
              violation.timezone
            ),
            "h:mm a EEE MMM d yyyy",
            {
              timeZone: violation.timezone,
            }
          )}</abbr
        ></time
      >
    </dd>
    <dt>Record #</dt>
    <dd><data value={violation.id}>{violation.number}</data></dd>
    <dt>Synced</dt>
    <dd>
      <time datetime={violation.generated}
        >{format(parseISO(violation.generated), "MMM d yyyy h:mm a zzz")}</time
      >
    </dd>
  </dl>

  {#if hasFees}
    <dl class="subjects">
      <dt>Fine</dt>
      <dd>
        <Fees {fees} />
      </dd>
    </dl>
  {/if}
  {#if paymentRequired}
    <form class="payment" on:submit={submitPayment}>
      <StripePayment
        bind:method={paymentMethod}
        {payment}
        bind:requestPayment
      />
      <!-- <p>Rules and cancellation policy</p> -->
      <!-- <data class="total" value="{payment.total.value}">{payment.total.display}</data> -->
      <!-- <input type="hidden" name="payment.source" value={paymentToken || ""} /> -->
      <button
        type="submit"
        disabled={!submittable || submitting}
        class={Object.entries(paymentMethod || {})
          .filter(([k, v]) => v)
          .map(([k, v]) => k)
          .join(" ")}>{submitting ? "Paying…" : "Pay"}</button
      >
    </form>
  {/if}
  <nav>
    <a
      class="rules"
      href="/{violation.scope ||
        violation.location?.id ||
        violation.location}/rules">Parking Rules</a
    >
  </nav>
  <!-- <dl class="subjects">
     
 </dl> -->
  <aside class="instructions">
    {#each [violation.property.violations?.info?.text].filter(identity) as text}
      <p>{text}</p>
    {/each}
  </aside>
{/if}
